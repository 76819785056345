<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Quality Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter Quality Name here..." maxlength="100" v-if="quality" v-model="quality.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Print Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="quality" v-model="quality.p_name">
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>

</template>

<script>
  import FabButton from '@/components/core/FabButton.vue'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'QualityForm',
    data () {
      return {
        quality: JSON.parse('{"id":"","code":0,"name":"","p_name":""}')
      }
    },
    props: {
      myquality: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"name":"","p_name":""}')
      }
    },
    beforeMount () {
      this.quality = this.myquality; // save props data to itself's data and deal with tms
    },
    component: {
      FabButton
    },
    created () {
      this.$data.quality = JSON.parse('{"id":"","code":0,"name":"","p_name":""}')
    },
    mounted () {

    },
    methods: {
      closeWindow() {
        this.$parent.$parent.closeModal();
      },
      saveDocument(){
        let self = this;

        if(self.$data.quality.name.trim().length < 1){
          alert("Invalid Name");
          return
        }

        const requestOptions = {
          method:  (self.$data.quality.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.quality)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/quality/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.quality = JSON.parse('{"id":"","code":0,"name":"","p_name":""}');
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
